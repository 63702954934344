import React from 'react';

import './styles.scss';

const SearchFail = () => (
  <div className="searchfail">
    <h2>La recherche n'a donné aucun résultat.</h2>
  </div>
);


export default SearchFail;
