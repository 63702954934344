function difficultyNameById(id) {
  if (id === 1) {
    return 'Facile';
  }
  if (id === 2) {
    return 'Moyen';
  }
  if (id === 3) {
    return 'Difficile';
  }
}
export default difficultyNameById;
